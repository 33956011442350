/* eslint-disable no-console */
import axios from 'axios';
import { useEffect, useState } from 'react';

const useFetchWeather = () => {
  const [weatherData, setWeatherData] = useState([]);

  useEffect(() => {
    async function getWeatherData() {
      axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
          'Access-Control-Allow-Headers':
            'x-access-token, Origin, X-Requested-With, Content-Type, Accept',
        },

        url: `https://api.bergregions.pl/api/v1/weather?lat=49.39082647987465&lon=20.100094465313852`,
      })
        .then((response) => {
          setWeatherData(response.data?.currentCondition);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    getWeatherData();

    const getRefreshWeatherData = () => {
      const minutes = new Date().getMinutes();
      if (minutes === 0 || minutes === 30) {
        getWeatherData();
      }
    };

    setInterval(() => {
      getRefreshWeatherData();
    }, 1000 * 60);
  }, []);

  return { weatherData };
};

export default useFetchWeather;
